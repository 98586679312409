/* body {
  cursor: none;
} */
/* a {
    cursor: none;
}
button {
    cursor: none;
} */

body {
  overflow-x: hidden !important;
}

.cursor {
  width: 25px;
  height: 25px;
  background: #dae0f1;
  border-radius: 50%;
  position: absolute;
  transition: all 0.1s ease;
  animation: cursorAnim 0.5s infinite alternate;
  mix-blend-mode: difference;
  z-index: 15;
}

.cursor::after {
  content: "";
  width: 30px;
  height: 10px;
  border: 15px solid #eff1f7;
  border-radius: 50%;
  position: absolute;
  opacity: 0.5;
  transition: all 0.1s ease;
  animation: cursorAnim2 0.5s infinite alternate;
}

@keyframes cursorAnim {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(0.5);
  }
}
@keyframes cursorAnim2 {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(0.4);
  }
}
